<template>
  <!-- NAME[epic=报表] 流水汇总报表 -->
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="120px"
    >
      <!-- 下单时间 -->
      <el-form-item label="" prop="">
        <el-select
          v-model="form.time_type"
          clearable
          style="width: 140px"
          placeholder="下单时间"
        >
          <el-option
            v-for="(i, idx) in timeTypeSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 时间 -->
      <el-form-item label="" prop="">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <!-- 部门 -->
      <el-form-item label="" prop="">
        <el-select
          v-model="form.depart_id"
          clearable
          style="width: 140px"
          placeholder="部门"
        >
          <el-option
            v-for="(i, idx) in departmentSelect"
            :key="idx"
            :value="i.id"
            :label="i.text"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 业务员 -->
      <el-form-item label="" prop="">
        <staff-select @change-staff="changeStaff"></staff-select>
      </el-form-item>
      <!-- 客户搜索 -->
      <el-form-item label="" prop="">
        <client-search
          :w="330"
          @select-val-alldata="clientChange"
        ></client-search>
      </el-form-item>
      <!-- 品类 -->
      <el-form-item label="" prop="">
        <class-select @change-class="changeClass"></class-select>
      </el-form-item>
      <!-- 品牌 -->
      <el-form-item label="" prop="">
        <brand-select @change-brand="changeBrand"></brand-select>
      </el-form-item>
      <!-- 商品搜索 -->
      <el-form-item label="" prop="">
        <goods-search
          :is-table="false"
          @select-goods-all="changeGoods"
        ></goods-search>
      </el-form-item>
      <!-- 结算方式 -->
      <el-form-item label="" prop="">
        <el-select
          v-model="form.account_id"
          clearable
          style="width: 140px"
          placeholder="结算方式"
        >
          <el-option
            v-for="(i, idx) in payTypeSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 是否结清 -->
      <el-form-item label="" prop="">
        <el-select
          v-model="form.pay_status"
          filterable
          clearable
          placeholder="是否结清"
          :popper-class="'select-idx'"
        >
          <el-option label="未结清" :value="0"></el-option>
          <el-option label="已结清" :value="1"></el-option>
          <el-option label="坏账" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <!-- 订单备注 -->
      <el-form-item label="" prop="">
        <el-input v-model="form.remark" placeholder="订单备注"></el-input>
      </el-form-item>
      <!-- 自定义客户组 -->
      <el-form-item label="" prop="">
        <el-select
          v-model="form.cust_group_id"
          clearable
          style="width: 140px"
          placeholder="自定义客户组"
        >
          <el-option
            v-for="(i, idx) in customClientSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 自定义商品组 -->
      <el-form-item label="" prop="">
        <el-select
          v-model="form.goods_group_id"
          clearable
          style="width: 140px"
          placeholder="自定义商品组"
        >
          <el-option
            v-for="(i, idx) in customGoodsSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 包含未到货预订单 -->
      <el-form-item v-if="form.time_type == 'create_at'" label="" prop="">
        <el-checkbox v-model="form.has_yu">包含未到货预订单</el-checkbox>
      </el-form-item>
      <!-- 查询流水 -->
      <el-form-item label="" prop="">
        <el-button type="primary" @click="handleQueryFlow(1)">
          查询流水
        </el-button>
      </el-form-item>
      <!-- 查询汇总 -->
      <el-form-item label="" prop="">
        <el-button type="warning" @click="handleQueryFlow(2)">
          查询汇总
        </el-button>
      </el-form-item>
      <!-- 导出 -->
      <el-form-item label="" prop="">
        <el-button @click="handleExport(1)">导出流水</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button @click="handleExport(2)">导出汇总</el-button>
      </el-form-item>
    </el-form>
    <div v-if="showTable" class="table">
      <!-- 商品5中表格 -->
      <el-card v-if="goodsTable.length > 0">
        <div
          v-for="(table, index) in goodsTable"
          :key="index"
          v-loading="loading"
        >
          <div
            v-for="(t, idx) in table.cust_list"
            :key="idx"
            v-loading="loading"
          >
            <!--  :show-summary="t.goods_btype == 1"
              :summary-method="heji" -->
            <el-table
              stripe
              :data="t.list"
              :span-method="(data) => objectTplSpanMethod(data, t.list)"
              :show-header="idx == 0"
            >
              <el-table-column
                :prop="type == 1 ? 'cust_name' : 'brand_name'"
                :label="t.goods_btype_text"
                width=""
                align="center"
              ></el-table-column>
              <el-table-column
                prop="goods_name"
                label="商品名称"
                width="220"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="sum_quantity_text"
                label="总销量"
                width=""
                align="center"
              ></el-table-column>
              <el-table-column
                prop="goods_type_text"
                label="类型"
                width=""
                align="center"
              ></el-table-column>
              <el-table-column
                prop="goods_price"
                label="单价"
                width=""
                align="center"
              >
                <template #default="{ row }">
                  <div :class="row.is_red ? 'fontcolor' : ''">
                    {{ row.goods_price }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="quantity"
                label="数量"
                width=""
                align="center"
              ></el-table-column>
              <el-table-column
                prop="goods_money"
                label="金额"
                width=""
                align="center"
              ></el-table-column>
              <el-table-column
                prop="goods_chengben"
                label="成本 "
                width=""
                align="center"
              ></el-table-column>
            </el-table>
            <div
              v-if="
                t.goods_btype_text && t.goods_btype_text.indexOf('退货') >= 0
              "
              class="total"
            >
              <div>退货金额: {{ t.goods_money }}</div>
              <!-- <div>退货退款: {{ t.refund_amount }}</div>
              <div>退货转存货: {{ t.refund_goods }}</div>
              <div>退货抵欠款: {{ t.refund_money }}</div> -->
              <div>毛利小结: {{ t.goods_maoli }}</div>
            </div>
            <div
              v-else-if="
                t.goods_btype_text &&
                t.goods_btype_text.indexOf('陈列兑付') >= 0
              "
              class="total"
            >
              <div>兑付商品货值: {{ t.goods_money }}</div>
              <div>成本合计: {{ t.goods_chengben }}</div>
              <div>兑付现金: {{ t.cash_amount }}</div>
            </div>
            <div v-else class="total">
              <!--              <div>数量合计: {{ t.sum_quantity_text }}</div>-->
              <div>销售小结: {{ t.goods_money }}</div>
              <div>赠送货值: {{ t.give_money }}</div>
              <div>毛利小结: {{ t.goods_maoli }}</div>
            </div>
          </div>
          <div class="total">
            <div>金额合计：{{ table.goods_money_sum }}</div>
            <div>毛利合计：{{ table.goods_maoli_sum }}</div>
          </div>
          <!-- 商品5中表格end -->
        </div>
      </el-card>
      <!-- 剩下的6种表格 不循环 -->
      <!-- 赊欠统计 -->
      <el-card v-if="listSheqian.list && listSheqian.list.length > 0">
        <base-table
          :list="listSheqian.list"
          :columns="columnsSheqian"
        ></base-table>
        <div class="total">
          <!--  应收总额：1000.00     已收款：600.00    已清欠：50.00    欠款合计：350.00 -->
          <div>应收总额: {{ listSheqian.sum.receiv_money }}</div>
          <div>已收款: {{ listSheqian.sum.pay_amount }}</div>
          <div>已清欠: {{ listSheqian.sum.qing_money }}</div>
          <div>欠款合计: {{ listSheqian.sum.left_amount }}</div>
        </div>
      </el-card>
      <!-- 清欠统计 -->
      <el-card v-if="listQingqian.list && listQingqian.list.length > 0">
        <base-table
          :list="listQingqian.list"
          :columns="columnsQingqian"
        ></base-table>
        <div class="total">
          <div>清欠收款合计: {{ listQingqian.sum.bill_amount }}</div>
        </div>
      </el-card>
      <!-- 预存款签约 -->
      <el-card v-if="listYucunkuan.list && listYucunkuan.list.length > 0">
        <base-table
          :list="listYucunkuan.list"
          :columns="columnsYucunkuan"
        ></base-table>
        <div class="total">
          <div>签约合计: {{ listYucunkuan.sum.total_amount }}</div>
          <div>赠送合计: {{ listYucunkuan.sum.gift_amount }}</div>
          <div>已收款合计: {{ listYucunkuan.sum.receive_amount }}</div>
          <div>待收款合计: {{ listYucunkuan.sum.left_amount }}</div>
          <div>退款: {{ listYucunkuan.sum.return_amount }}</div>
        </div>
      </el-card>
      <!-- 预存或 -->
      <el-card v-if="listYucunhuo.list && listYucunhuo.list.length > 0">
        <base-table
          :list="listYucunhuo.list"
          :columns="columnsYucunhuo"
        ></base-table>
        <div class="total">
          <div>签约合计: {{ listYucunhuo.sum.total_amount }}</div>
          <div>已收款合计: {{ listYucunhuo.sum.receive_amount }}</div>
          <div>待收款合计: {{ listYucunhuo.sum.left_amount }}</div>
          <div>退款: {{ listYucunhuo.sum.return_amount }}</div>
        </div>
      </el-card>
      <!-- 陈列 -->
      <el-card v-if="listChenlie.list && listChenlie.list.length > 0">
        <base-table
          :list="listChenlie.list"
          :columns="columnsChenlie"
        ></base-table>
        <div class="total">
          <div>承兑合计: {{ listChenlie.sum.total_amount }}</div>
        </div>
      </el-card>
      <!-- 已结清订单 -->
      <el-card v-if="listEnd.list && listEnd.list.length > 0">
        <base-table :list="listEnd.list" :columns="columnsEnd"></base-table>
        <div class="total">
          <div>金额合计: {{ listEnd.sum.total_amount }}</div>
          <div>毛利合计: {{ listEnd.sum.gross_profit_amount }}</div>
        </div>
      </el-card>
      <!-- 剩下的6种表格 不循环 end -->
    </div>
    <!-- 合计 -->
    <div v-if="allTotal.receive_amount" class="total">
      <div>销售实收:{{ allTotal.receive_amount }}</div>
      <div>退货金额:{{ allTotal.refund_amount }}</div>
      <div>预存款收款:{{ allTotal.pre_receive_amount }}</div>
      <div>预存款退款:{{ allTotal.pre_return_amount }}</div>
      <div>预存货收款:{{ allTotal.goods_receive_amount }}</div>
      <div>预存货退款:{{ allTotal.goods_return_amount }}</div>
      <div>清欠收款:{{ allTotal.qing_money }}</div>
      <div>陈列兑付现金:{{ allTotal.cash_amount }}</div>
      <div>收款总计:{{ allTotal.shou_sum }}</div>
      <div>毛利总计:{{ allTotal.maoli_sum }}</div>
    </div>
  </div>
</template>
<script>
  import staffSelect from '@/baseComponents/staffSelect'
  import brandSelect from '@/baseComponents/brandSelect'
  import classSelect from '@/baseComponents/classSelect'
  import clientSearch from '@/baseComponents/clientSearch'
  import goodsSearch from '@/baseComponents/goodsSearch'
  // 部门下拉
  import { departmentList } from '@/api/performance'
  import { downloadFile, postAction } from '@/api/Employee'
  import baseTable from '@/baseComponents/baseTable'
  import { day_n } from '@/utils/Time'
  export default {
    name: 'StaffFlowSummary',
    components: {
      staffSelect,
      brandSelect,
      classSelect,
      clientSearch,
      goodsSearch,
      baseTable,
    },
    data() {
      return {
        loading: false,
        spanArr: [],
        list: [],
        type: 1,
        showTable: false,
        goodsTable: [],
        form: {
          time_type: 'create_at',
          // start_time: '2021-10-01',
          // end_time: '2021-10-10',
        },
        timeTypeSelect: [
          {
            id: 'create_at',
            name: '下单时间',
          },
          {
            id: 'delivery_at',
            name: '派单时间',
          },
          {
            id: 'arrive_at',
            name: '到货时间',
          },
          {
            id: 'pay_status_time',
            name: '结清时间',
          },
        ],
        time: [],
        departmentSelect: [],
        // 1货到付款2账期结算
        payTypeSelect: [
          {
            id: '1',
            name: '货到付款',
          },
          {
            id: '2',
            name: '账期结算',
          },
        ],
        customGoodsSelect: [],
        customClientSelect: [],

        /**
         * 六种表格
         */
        // 赊欠统计
        listSheqian: [],
        columnsSheqian: [
          {
            label: '赊欠统计（客户）',
            prop: 'cust_name',
            width: '',
          },
          {
            label: '单号',
            prop: 'bill_code',
            width: '',
          },
          {
            label: '下单时间',
            prop: 'create_at',
            width: '',
          },
          {
            label: '业务员',
            prop: 'staff_name',
            width: '',
          },
          {
            label: '应收款',
            prop: 'receiv_money',
            width: '',
          },
          {
            label: '已收款',
            prop: 'pay_amount',
            width: '',
          },
          {
            label: '已清欠',
            prop: 'qing_money',
            width: '',
          },
          {
            label: '剩余欠款',
            prop: 'left_amount',
            width: '',
          },
        ],
        // 清欠统计
        listQingqian: [],
        columnsQingqian: [
          {
            label: '清欠统计（客户）',
            width: '',
            prop: 'cust_name',
          },
          {
            label: '单号',
            width: '',
            prop: 'bill_code',
          },
          {
            label: '下单时间',
            prop: 'create_at_sale',
            width: '',
          },
          {
            label: '业务员',
            width: '',
            prop: 'staff_name',
          },
          {
            label: '清欠金额',
            width: '',
            prop: 'bill_amount',
          },
          {
            label: '清欠时间',
            width: '',
            prop: 'create_at',
          },
          {
            label: '清欠备注',
            width: '',
            prop: 'remark',
          },
        ],
        //预存款
        listYucunkuan: [],
        columnsYucunkuan: [
          {
            label: '预存款签约',
            width: '',
            prop: 'cust_name',
          },
          {
            label: '单号',
            width: '',
            prop: 'bill_code',
          },
          {
            label: '下单时间',
            prop: 'create_at',
            width: '',
          },
          {
            label: '业务员',
            width: '',
            prop: 'staff_name',
          },
          {
            label: '签约金额',
            width: '',
            prop: 'total_amount',
          },
          {
            label: '赠送金额',
            width: '',
            prop: 'gift_amount',
          },
          {
            label: '已收款',
            width: '',
            prop: 'receive_amount',
          },
          {
            label: '待收款',
            width: '',
            prop: 'left_amount',
          },
        ],
        //预存货
        listYucunhuo: [],
        columnsYucunhuo: [
          {
            label: '预存货签约',
            prop: 'cust_name',
            width: '',
          },
          {
            label: '单号',
            prop: 'bill_code',
            width: '',
          },
          {
            label: '下单时间',
            prop: 'create_at',
            width: '',
          },
          {
            label: '业务员',
            prop: 'staff_name',
            width: '',
          },
          {
            label: '签约金额',
            prop: 'total_amount',
            width: '',
          },
          {
            label: '已收款',
            prop: 'receive_amount',
            width: '',
          },
          {
            label: '待收款',
            prop: 'left_amount',
            width: '',
          },
        ],
        //陈列
        listChenlie: [],
        columnsChenlie: [
          {
            label: '付费陈列签约',
            width: '',
            prop: 'cust_name',
          },
          {
            label: '单号',
            width: '',
            prop: 'bill_code',
          },
          {
            label: '下单时间',
            prop: 'create_at',
            width: '',
          },
          {
            label: '业务员',
            width: '',
            prop: 'staff_name',
          },
          {
            label: '陈列天数',
            width: '',
            prop: 'paid_days',
          },
          {
            label: '承兑货值',
            width: '',
            prop: 'total_amount',
          },
          {
            label: '陈列开始时间',
            width: '',
            prop: 'start_time',
          },
        ],
        // 已结清订单
        listEnd: [],
        columnsEnd: [
          {
            label: '已结清订单（客户）',
            width: '',
            prop: 'cust_name',
          },
          {
            label: '单号',
            width: '',
            prop: 'bill_code',
          },
          {
            label: '业务员',
            width: '',
            prop: 'staff_name',
          },
          {
            label: '订单金额',
            width: '',
            prop: 'total_amount',
          },
          {
            label: '下单时间',
            width: '',
            prop: 'create_at',
          },
          {
            label: '结清时间',
            width: '',
            prop: 'pay_status_time',
          },
          {
            label: '毛利',
            width: '',
            prop: 'gross_profit_amount',
          },
        ],

        allTotal: {},
      }
    },
    computed: {},
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {
      this.initSelect()
      this.initTime()
    },
    mounted() {},
    methods: {
      headerStyleEvent({ row, column, rowIndex, columnIndex }) {
        if (columnIndex == 0) {
          return 'background-color:#169BD5'
        }
      },

      initTime() {
        this.time = [day_n(3)[1], day_n(3)[1]]
        this.form.start_time = day_n(3)[1]
        this.form.end_time = day_n(3)[1]
      },
      initSelect() {
        // 自定义商品分组下拉
        postAction('/baseAdmin/goods-group/index', { pageSize: -1 }).then(
          (r) => {
            this.customGoodsSelect = r.data
          }
        )
        postAction('/baseAdmin/customer-group/index', { pageSize: -1 }).then(
          (r) => {
            this.customClientSelect = r.data
          }
        )
        // 部门下拉
        departmentList().then((r) => {
          this.departmentSelect = r.data.list
        })
      },
      changeStaff(v) {
        this.form.staff_id = v
      },
      clientChange(v) {
        this.form.cust_id = v.id
      },
      changeClass(v) {
        this.form.class_id = v
      },
      changeBrand(v) {
        this.form.brand_id = v
      },
      changeGoods(v) {
        if (v.goods_id > 0) {
          this.form.goods_id = v.goods_id
        } else {
          this.form.goods_id = ''
        }
      },
      // 1查流水 2查汇总
      async handleQueryFlow(type) {
        this.loading = true
        if (!this.time) {
          this.time = [day_n(3)[1], day_n(3)[1]]
          this.form.start_time = day_n(3)[1]
          this.form.end_time = day_n(3)[1]
        }

        let url =
          type == 1
            ? '/saleAdmin/sale-order-form/flow'
            : '/saleAdmin/sale-order-form/flow-summary'

        let { data } = await postAction(url, this.form)

        this.goodsTable = data.sale_list_data // 5中
        if (type == 2) {
          this.type = 2
          this.goodsTable.forEach((i) => {
            i.cust_list = i.brand_list
          })
        } else {
          this.type = 1
        }
        // 赊欠统计
        this.listSheqian = data.arrears_list_data
        // 清欠统计
        this.listQingqian = data.qing_list_data
        // 预存款
        this.listYucunkuan = data.pre_list_data
        // 预存货
        this.listYucunhuo = data.goods_list_data
        // 陈列
        this.listChenlie = data.paid_list_data
        // 已结清订单
        this.listEnd = data.settle_list_data
        // total
        this.allTotal = data.all_data

        this.showTable = true
        this.loading = false
      },
      handleExport(type) {
        // type1 导出流水   2 汇总
        let url = '',
          name = ''
        if (type == 1) {
          url = '/saleAdmin/sale-order-form/flow-export'
          name = '导出流水.xlsx'
        } else {
          url = '/saleAdmin/sale-order-form/flow-summary-export'
          name = '导出汇总.xlsx'
        }
        downloadFile(url, name, this.form)
      },

      /**
       * 合并单元格
       */
      flitterData(arr, name) {
        let spanOneArr = [],
          concatOne = 0
        arr.forEach((item, index) => {
          if (index === 0) {
            spanOneArr.push(1)
          } else {
            if (item[name] === arr[index - 1][name]) {
              //第一列需合并相同内容的判断条件
              console.log('第一列需合并相同内容的判断条件')
              spanOneArr[concatOne] += 1
              spanOneArr.push(0)
            } else {
              spanOneArr.push(1)
              concatOne = index
            }
          }
        })
        return spanOneArr
      },
      /**
       * 合并列
       */
      objectTplSpanMethod(data, rows) {
        let { row, column, rowIndex, columnIndex } = data
        if (columnIndex === 0) {
          const _row = this.flitterData(rows, 'cust_name')[rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col,
          }
        } else if (columnIndex === 1) {
          const _row = this.flitterData(rows, 'goods_name')[rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col,
          }
        } else if (columnIndex === 2) {
          const _row = this.flitterData(rows, 'goods_id')[rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col,
          }
        }
      },
      heji(param) {
        const { columns, data } = param
        // 需要合计的下标
        let i = [2]
        let sums = []
        // let val = data.map((item) => {
        //   console.log(item)
        // })
        columns.forEach((col, idx) => {
          i.forEach((n) => {
            if (idx == n) {
              const val = data.map((item) => Number(item[sum_quantity]))
              console.log(val)
              if (!val.every((value) => isNaN(value))) {
                const a = val.reduce((total, num) => {
                  if (!isNaN(total)) {
                    return total + num
                  }
                })
                sums[n] = a.toFixed(2)
              }
            }
          })
          if (idx == 0) {
            sums[idx] = '合计'
          }
        })
        console.log('sss', sums)
        return sums
      },
    },
  }
</script>
<style lang="scss" scoped>
  .total {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0;
    div {
      margin: 0 20px;
    }
  }
  .fontcolor {
    color: red;
  }
</style>
